import {BehaviorSubject, Observable} from "rxjs";
import { ImageDataService } from './image-data-service';
import { Injectable } from '@angular/core';
import { Point } from '@angular/cdk/drag-drop';
import { GreenImageData, Color, SegmentProfile, Segment, SegmentHole, HoleCountData, SaveSegmentModel, SaveSegmentProfile } from './custom-types'
import { NgxUiLoaderService } from "ngx-ui-loader";
// import jsPDF, { ImageProperties } from "jspdf";
// import html2canvas from "html2canvas";
@Injectable({
    providedIn: 'root'
})

export class SegmentBookService {
    depths: number[];
    canvasContainer: HTMLElement;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    configData: any;
    colorProfile: any;
    segmentProfiles: SegmentProfile[] = [];
    image: HTMLImageElement;
    resizeHeight: number = 600
    resizeWidth: number = 825

    private _progress: BehaviorSubject<number> = new BehaviorSubject(0);
    public progressUpdates: Observable<number> = this._progress.asObservable()

    private set progress(value:number) {
        this._progress.next(value);
    }

    private get progress() {
        return this._progress.value;
    }

    constructor (
      private imgdataService: ImageDataService
    ) {
    }

    reset() {
        this.progress = 0
        this.depths = []
        this.configData = {} as any
        this.colorProfile = []
        this.segmentProfiles = []
        this.resizeHeight = 600
        this.resizeWidth = 825
    }

    async segmentBook(canvasContainer:HTMLElement, colorProfile:any, configData:any, scanFiles:File[], depthFile:File, onProgress:CallableFunction|null=null
    ) : Promise<SegmentProfile[]> {
        console.log(scanFiles, '***scan-files')
        console.log(depthFile, '***depth-files')
        this.canvasContainer = canvasContainer
        this.canvas = <HTMLCanvasElement>canvasContainer.getElementsByClassName("image-canvas")[0]
        // this.canvas.style.removeProperty("max-width")
        // this.canvas.style.removeProperty("max-height")
        let ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d")
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.configData = {...configData}
        this.colorProfile = colorProfile

        this.progress = 0;
        console.time("READ_DEPTH_FILE")
        this.depths = await this.readDepthFile(depthFile)
        console.timeEnd("READ_DEPTH_FILE")
        this.progress += 5;

        console.time("READ_SCAN_FILES")
        this.segmentProfiles = await this.readScanFiles(scanFiles, 80)
        console.timeEnd("READ_SCAN_FILES")

        let currentPage = 0
        let mode = 0
        console.time("EXECUTE_SEGMENT")
        this.segmentProfiles[currentPage] = await this.executeSegment(this.segmentProfiles[currentPage])
        this.progress += + 10;
        console.timeEnd("EXECUTE_SEGMENT")

        console.time("SET_COUNTS")
        this.segmentProfiles[currentPage] = this.setCounts(this.segmentProfiles[currentPage])
        this.progress = 100;
        console.timeEnd("SET_COUNTS")


        return this.segmentProfiles
    }

    async readDepthFile(depthFile:File) : Promise<any> {
        //to check for csv reader packages
        return new Promise((resolve, reject) => {
            let csvReader = new FileReader()
            csvReader.readAsText(depthFile)
            csvReader.onload = () => {
                let content = csvReader.result as string
                let csvRows = content?.split(/[\r\n]+/);
                let csvData = csvRows.map((row) => {
                    if(parseInt(row.split(',')[0])){
                        return parseInt(row.split(',')[0])
                    }else{
                        console.warn(`${row.split(',')[0]} is not a number`);
                        return 0;
                    }
                })
                resolve(csvData)
            }
        })
    }

    /**
     * Read scan files and create SegmentProfiles
     * @param {FileList|File[]} scanFiles
     * @param {number} progressCap - Max progress allocated for this operation, used for showing progressbar
     */
    async readScanFiles(scanFiles: File[], progressCap = 100) : Promise<SegmentProfile[]> {
        scanFiles = [...scanFiles].filter(f => this.isValidScanFile(f))
        const progressPerFile = progressCap / Math.max(scanFiles.length, 1);
        let segmentProfiles: SegmentProfile[] = []
        for(let i=0; i<scanFiles.length; i++) {
            let currentProfile = await this.readScanFile(scanFiles[i], this.depths[i])
            segmentProfiles.push(currentProfile)
            this.progress += progressPerFile;
        }
        return segmentProfiles
    }

    async readScanFile(file: File, depth: number) : Promise<SegmentProfile> {
        let imageData = await this.getImageData(file)
        let greenImageData = this.imgdataService.greenImage(imageData, true)
        let yardLength = (greenImageData.bottom - greenImageData.top)/depth
        return this.getSegmentProfile(greenImageData, {...this.configData, yardLength}, file.name)
    }

    isValidScanFile(file: File): boolean {
        let fileName = file.name
        return (fileName.endsWith(".png")
          || fileName.endsWith(".jpeg")
          || fileName.endsWith(".jpg")
          || fileName.endsWith(".bmp")
        )
    }

    async getImageData(imgFile: File) : Promise<ImageData> {
        return new Promise((resolve, reject) =>{
            let imgReader = new FileReader();
            imgReader.readAsDataURL(imgFile)
            imgReader.onload = () => {
                let img = <HTMLImageElement>this.canvasContainer.getElementsByClassName("green-image")[0]
                this.image = img
                img.src = imgReader.result as string
                img.onload = () => {
                    let imgData: ImageData
                    this.canvas.width = img.width
                    this.canvas.height = img.height
                    this.ctx = <CanvasRenderingContext2D>this.canvas.getContext('2d')
                    this.ctx?.drawImage(img, 0, 0)
                    imgData = this.ctx?.getImageData(0, 0, img.width, img.height)
                    console.log("image data:", imgData)
                    resolve(imgData)
                }
            }
        })
    }

    //SegmentProfile
    getSegmentProfile(data: any, configData: any, fileName: string) : SegmentProfile {
        let croppedHeight = data.top
        let croppedWidth = data.left
        let segmentProfile: SegmentProfile = {
            colorProfile: this.colorProfile,
            configData: configData,
            originalFileName: fileName,
            croppedImageData: this.cropImage(data),
            executed: false,
            loadedFromSave: false,
            finalImageData: {} as GreenImageData,
            segments: [],
            addedHoles: [],
            addedHoleNames: [],
            holeCountData: [],
            shrinkedWidthRatio: 1,
            shrinkedHeightRatio: 1,
            croppedHeight: croppedHeight,
            croppedWidth: croppedWidth,
            borderPixel: croppedWidth > 0 && croppedHeight > 0 ? data.pixels.data.slice(0, 4) : []
        }
        return segmentProfile
    }

    cropImage(imgData:any, useHiddenCanvas: boolean = false) : ImageData {
        // can simplify the width and height calculation like below.
        // retaining the original logic from old IGP software
        // let heightToCrop = imgData.bottom - imgData.top + 1
        // let widthToCrop = imgData.right - imgData.left + 1
        let ctx = this.ctx
        let canvas = this.canvas
        if(useHiddenCanvas) {
            canvas = <HTMLCanvasElement>this.canvasContainer.querySelector(".hidden-canvas")
            ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
        }

        let heightToCrop = imgData.height - (imgData.height - imgData.bottom)-imgData.top+1
        let widthToCrop = imgData.width-(imgData.width - imgData.right)-imgData.left+1
        let croppedImageData = ctx.getImageData(imgData.left, imgData.top, widthToCrop, heightToCrop)
        console.log(croppedImageData, '***croppedImageData')

        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.putImageData(croppedImageData, 0, 0)
        return croppedImageData
    }

    async executeSegment(segmentProfile: SegmentProfile, useHiddenCanvas: boolean = false, isResize: boolean = true, withoutGrid: boolean = false) : Promise<SegmentProfile> {
        return new Promise(async (resolve, reject) => {
            if(segmentProfile.configData.useContour) {
                segmentProfile = this.getSegments(segmentProfile)
                console.log(segmentProfile, '**after-getting-segments')
                segmentProfile = this.createLRHoles(segmentProfile)
                console.log(segmentProfile, '**after-creating-holes')
            }
            else {
                segmentProfile = this.getSegmentsNew(segmentProfile)
                console.log(segmentProfile, '**after-getting-segments')
                segmentProfile = this.createLRHolesNew(segmentProfile)
                console.log(segmentProfile, '**after-creating-holes')
            }
            segmentProfile = this.scoreHoles(segmentProfile)
            console.log(segmentProfile, '**after-scoring-holes')
            segmentProfile = await this.blurGreen(segmentProfile, useHiddenCanvas, isResize)
            if(!useHiddenCanvas)
                this.drawHoles(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
            if(!withoutGrid)
                this.drawGrid(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio, useHiddenCanvas)

            segmentProfile.executed = true
            resolve(segmentProfile)
        })
    }

    getSegmentsNew(segmentProfile: SegmentProfile) : SegmentProfile {
        let originalData = this.imgdataService.greenImage(segmentProfile.croppedImageData,true)
        let configData = segmentProfile.configData
        let subsegments: Segment[] = []
        let points: Point[] = []
        let depth = 0
        let onTransparent = true
        for(let y = originalData.bottom; y >= 0 ; y -= configData.yardLength) {
            for(let x = 0; x < originalData.width; x++) {
                let color = this.imgdataService.getColor(originalData, Math.round(x), Math.round(y))

                if(color.A != 0 && onTransparent) {
                    points = this.addPoint(points, x, y)
                    console.log(points, '***points')
                    onTransparent = false
                }
                else if((color.A == 0 && !onTransparent) || (x == originalData.width-1 && !onTransparent)) {
                    points = this.addPoint(points, x-1, y)
                    subsegments.push(this.getNewSegment(points, depth))
                    onTransparent = true
                    points = []
                }                
            }
            onTransparent = true
            depth++
        }

        for(let a=0; a<subsegments.length; a++) {
            let vls: Point[] = []
            vls = this.addPoint(vls, 0, 1)
            subsegments[a].validLocations = vls
            subsegments[a].valid = true
        }

        console.log(subsegments, '***new-segments')
        segmentProfile.finalImageData = this.imgdataService.greenImage(segmentProfile.croppedImageData)
        segmentProfile.segments = subsegments

        return segmentProfile
    }

    getSegments(segmentProfile: SegmentProfile) : SegmentProfile {
        let originalData = this.imgdataService.greenImage(segmentProfile.croppedImageData,true)
        let configData = segmentProfile.configData
        let subsegments: Segment[] = []
        let points: Point[] = []
        let depth = 1
        let onTransparent = true
        for(let y = (originalData.bottom - configData.yardLength); y >= 0; y -= configData.yardLength) {
            for(let x = 0; x < originalData.width; x++) {
                let color = this.imgdataService.getColor(originalData, Math.round(x), Math.round(y))

                if(color.A != 0 && onTransparent) {
                    points = this.addPoint(points, x, y)
                    console.log(points, '***points')
                    onTransparent = false
                }
                else if((color.A == 0 && !onTransparent) || (x == originalData.width-1 && !onTransparent)) {
                    points = this.addPoint(points, x-1, y)

                    let onBlack = false
                    let blackCount = 0

                    for(let xx=points[0].x + configData.yardLength/2; xx<points[points.length-1].x-configData.yardLength/2; xx++) {
                        let colorCC = this.imgdataService.getColor(originalData, Math.round(xx), Math.round(y))

                        if((colorCC.R + colorCC.G + colorCC.B < 90) && colorCC.A != 0 && !onBlack) {
                            onBlack = true
                        }

                        if(onBlack) {
                            for(let a=xx; a<originalData.width; a++) {
                                let colorD = this.imgdataService.getColor(originalData, Math.round(a), Math.round(y))

                                if((colorD.R + colorD.G + colorD.B < 90) && onBlack) {
                                    blackCount++
                                }
                                else {
                                    onBlack = false
                                    break
                                }
                            }
                            // points = this.addPoint(points, xx+blackCount/2, y) //inserting in last position
                            let p: Point = {
                                x: xx+blackCount/2,
                                y: y
                            }
                            points.splice(points.length-1, 0, p)
                            onBlack = false
                            xx += blackCount + 1
                            blackCount = 0
                        }
                        blackCount = 0
                    }
                    subsegments.push(this.getNewSegment(points, depth))
                    onTransparent = true
                    points = []
                }
            }
            onTransparent = true
            depth++
        }

        for(let a=0; a<subsegments.length; a++) {
            let size = subsegments[a].points.length
            if(size < 3) {
                subsegments.splice(a, 1)
                a--
            }
            else if (size == 3){
                let white = false
                let midPoint = subsegments[a].points[1]
                for(let y = midPoint.y; y < midPoint.y + configData.yardLength; y++) {
                    let color = this.imgdataService.getColor(originalData, Math.round(midPoint.x), Math.round(y))
                    if(color.A == 0) {
                        white = true
                        break
                    }
                }
                if(!white) {
                    for(let y = midPoint.y; y > midPoint.y - configData.yardLength; y--) {
                        let color = this.imgdataService.getColor(originalData, Math.round(midPoint.x), Math.round(y))
                        if(color.A == 0) {
                            white = true
                            break
                        }
                    }
                }
                if(!white) {
                    let vls: Point[] = []
                    vls = this.addPoint(vls, 1, 1)
                    // let p: Point = {
                    //     x: 1,
                    //     y: 1
                    // }
                    // vls.push(p)
                    subsegments[a].validLocations = vls
                    subsegments[a].valid = true
                }
            }
            else if(size == 4) {
                let vls: Point[] = []
                vls = this.addPoint(vls, 1, 2)
                subsegments[a].validLocations = vls
                subsegments[a].valid = true
            }
            else if(size == 5) {
                let vls: Point[] = []
                vls = this.addPoint(vls, 0, 4)
                subsegments[a].validLocations = vls
                subsegments[a].valid = true
            }
            else if(size == 6) {
                let vls: Point[] = []
                vls = this.addPoint(vls, 1, 2)
                vls = this.addPoint(vls, 3, 4)
                subsegments[a].validLocations = vls
                subsegments[a].valid = true
            }
            else {
                let vls: Point[] = []
                vls = this.addPoint(vls, 0, size-1)
                subsegments[a].validLocations = vls
                subsegments[a].valid = true
            }
        }
        for(let a=0; a<subsegments.length; a++) {
            if(!subsegments[a].valid) {
                subsegments.splice(a, 1)
                a--
            }
        }
        //finalImageData??
        segmentProfile.finalImageData = this.imgdataService.greenImage(segmentProfile.croppedImageData)
        segmentProfile.segments = subsegments

        return segmentProfile
    }

    getNewSegment(points: Point[], depth: number) : Segment {
        let segment: Segment = {
            points: points,
            validLocations: [],
            holes: [],
            valid: false,
            depth: depth,
            midpoint: {} as SegmentHole
        }
        return segment
    }

    addPoint(points: Point[], x: number, y: number) : Point[] {
        let p: Point = {
            x: x,
            y: y
        }
        points.push(p)
        return points
    }

    // getColor(originalData: any, x: number, y: number) : Color {
    //     let color:Color = {
    //         R: originalData.pixels.data[(y * (originalData.width * 4) + x * 4)],
    //         G: originalData.pixels.data[(y * (originalData.width * 4) + x * 4) + 1],
    //         B: originalData.pixels.data[(y * (originalData.width * 4) + x * 4) + 2],
    //         A: originalData.pixels.data[(y * (originalData.width * 4) + x * 4) + 3]
    //     }
    //     return color
    // }

    createLRHolesNew(segmentProfile: SegmentProfile) : SegmentProfile {
        let DFEYards = segmentProfile.configData.distanceFromEdge
        let DFEPixels = DFEYards * segmentProfile.configData.yardLength
        let DFETolerancePixels = segmentProfile.configData.distanceFromEdgeTolerance * segmentProfile.configData.yardLength
        let segments = segmentProfile.segments
        for(let t=0; t<segments.length; t++) {
            let currentSegment = segments[t]
            // let size = currentSegment.points.length

            let validLocations = currentSegment.validLocations
            let p = validLocations[0]
            let lHolesToAdd: Point[] = []
            let rHolesToAdd: Point[] = []
            let lPointInd = p.x
            let rPointInd = p.y

            let lPoint = currentSegment.points[lPointInd]
            let rPoint = currentSegment.points[rPointInd]

            let lBar:Point = {
                x: currentSegment.points[lPointInd].x + DFEPixels,
                y: currentSegment.points[lPointInd].y
            }
                
            let rBar:Point = {
                x: currentSegment.points[rPointInd].x - DFEPixels,
                y: currentSegment.points[rPointInd].y
            }

            let lCurr = {...lPoint}
            let rCurr = {...rPoint}
            let lPrev = lCurr
            let rPrev = rCurr

            let currentConfigData = segmentProfile.configData

            //Old logic - with center tolerance
            lCurr = this.findStartPoint(lCurr, segmentProfile.finalImageData, DFEPixels, segmentProfile.configData.yardLength, false, rBar, DFETolerancePixels)
            rCurr = this.findStartPoint(rCurr, segmentProfile.finalImageData, DFEPixels, segmentProfile.configData.yardLength, true, lBar, DFETolerancePixels)

            // If the distance between points is less than center tolerance at first itself
            if(rPrev.x != -1 && lPrev.x != -1 && rPrev.x - lPrev.x < currentConfigData.yardLength * currentConfigData.centerTolerance) {
                if(rPrev.x - lPrev.x >= currentConfigData.yardLength) {
                    lHolesToAdd.push(lCurr)
                    rHolesToAdd.push(rCurr)
                }
                else {
                    this.addHolesToSegment({x: (rPrev.x + lPrev.x)/2, y: lPrev.y}, currentSegment.holes)
                }
            }
            while((rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance)) {
                lHolesToAdd.push(lCurr)
                lPrev = lCurr
                lCurr = {
                    x: lCurr.x + currentConfigData.yardLength,
                    y: lCurr.y
                }

                if((rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance)) {
                    rHolesToAdd.push(rCurr)
                    rPrev = rCurr
                    rCurr = {
                        x: rCurr.x - currentConfigData.yardLength,
                        y: rCurr.y
                    }
                }
            }

            for(let a=0; a<lHolesToAdd.length; a++) {
                currentSegment.holes = this.addHolesToSegment(lHolesToAdd[a], currentSegment.holes)
            }

            for(let a=0; a<rHolesToAdd.length; a++) {
                currentSegment.holes = this.addHolesToSegment(rHolesToAdd[a], currentSegment.holes)
            }
        }
        segmentProfile.finalImageData = this.imgdataService.greenImage(segmentProfile.finalImageData.pixels, true)
        return segmentProfile
    }

    findStartPoint (holeLoc: Point, imgData: GreenImageData, DFEPixels: number, yardLength: number, isRight: boolean, limit: Point, DFETolerancePixels: number) {
        let offset = yardLength/10
        holeLoc.x = isRight ? holeLoc.x - DFEPixels : holeLoc.x + DFEPixels
        while(this.ignoreHoles(holeLoc, imgData, DFEPixels-DFETolerancePixels)) {
            if(isRight) {
                holeLoc.x -= offset
                if(holeLoc.x <= limit.x) {
                    holeLoc.x = -1
                    holeLoc.y = -1
                    break;
                }
            }
            else {
                holeLoc.x += offset
                if(holeLoc.x >= limit.x){
                    holeLoc.x = -1
                    holeLoc.y = -1
                    break;
                }
            }
        }
        return holeLoc
    }

    ignoreHoles (holeLoc: Point, imgData: GreenImageData, DFEPixels: number) {
        for(let x = holeLoc.x - DFEPixels; x <= holeLoc.x + DFEPixels; x++) {
            for(let y = holeLoc.y - DFEPixels; y <= holeLoc.y + DFEPixels; y++) {
                if(x >= 0 && x <= imgData.width && y >= 0 && y <= imgData.height) {
                    let distance = Math.sqrt(Math.pow(Math.abs(holeLoc.x - x), 2) + 
                        Math.pow(Math.abs(holeLoc.y - y), 2))
                    if(distance <= DFEPixels) {
                        let color = this.imgdataService.getColor(imgData, Math.round(x), Math.round(y))
                        if(color.A == 0) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    createLRHoles(segmentProfile: SegmentProfile) : SegmentProfile {
        let segments = segmentProfile.segments
        for(let t=0; t<segments.length; t++) {
            let currentSegment = segments[t]
            let size = currentSegment.points.length
            if(size < 4) {
                let holeLoc = currentSegment.points[currentSegment.validLocations[0].x]
                currentSegment.holes = this.addHolesToSegment(holeLoc, currentSegment.holes)
            }
            else if(size == 4) {
                let validLocations = currentSegment.validLocations
                let p = validLocations[0]

                let lHolesToAdd: Point[] = []
                let rHolesToAdd: Point[] = []

                let lPointInd = p.x
                let rPointInd = p.y

                let lPoint = currentSegment.points[lPointInd-1]
                let rPoint = currentSegment.points[rPointInd+1]
                let lBar = currentSegment.points[lPointInd]
                let rBar = currentSegment.points[rPointInd]

                let lCurr = lPoint
                let rCurr = rPoint
                let lPrev = lCurr
                let rPrev = rCurr

                let currentConfigData = segmentProfile.configData
                while(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                    lHolesToAdd.push(lCurr)
                    lPrev = lCurr
                    lCurr = {
                        x: lCurr.x + currentConfigData.yardLength,
                        y: lCurr.y
                    }
                    // lCurr.x = lCurr.x + currentConfigData.yardLength

                    if(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                        rHolesToAdd.push(rCurr)
                        rPrev = rCurr
                        // rCurr.x = rCurr.x - currentConfigData.yardLength
                        rCurr = {
                            x: rCurr.x - currentConfigData.yardLength,
                            y: rCurr.y
                        }
                    }
                }

                let leftMost: Point = {
                    x: -1,
                    y: -1
                }

                let rightMost: Point = {
                    x: -1,
                    y: -1
                }

                for(let a=0; a<lHolesToAdd.length; a++) {
                    let check = lHolesToAdd[a]
                    if(check.x < lBar.x - currentConfigData.contourLeniency || check.x > rBar.x + currentConfigData.contourLeniency) {
                        lHolesToAdd.splice(a, 1)
                        a--
                    }
                    else {
                        currentSegment.holes = this.addHolesToSegment(lHolesToAdd[a], currentSegment.holes)
                        rightMost.x = lHolesToAdd[a].x
                        rightMost.y = lHolesToAdd[a].y
                    }
                }

                for(let a=0; a<rHolesToAdd.length; a++) {
                    let check = rHolesToAdd[a]
                    if(check.x > rBar.x + currentConfigData.contourLeniency || check.x < lBar.x - currentConfigData.contourLeniency) {
                        rHolesToAdd.splice(a, 1)
                        a--
                    }
                    else {
                        currentSegment.holes = this.addHolesToSegment(rHolesToAdd[a], currentSegment.holes)
                        leftMost.x = rHolesToAdd[a].x
                        leftMost.y = rHolesToAdd[a].y
                    }
                }
            }
            else if(size == 6) {
                let validLocations = currentSegment.validLocations
                let farLeft = validLocations[0]
                let farRight = validLocations[validLocations.length - 1]

                let lHolesToAdd: Point[] = []
                let rHolesToAdd: Point[] = []

                let lPointInd = farLeft.x
                let rPointInd = farRight.y

                let lPoint = currentSegment.points[lPointInd-1]
                let rPoint = currentSegment.points[rPointInd+1]
                let lBar = currentSegment.points[lPointInd]
                let rBar = currentSegment.points[rPointInd]
                let innerlBar = currentSegment.points[lPointInd+1]
                let innerrBar = currentSegment.points[rPointInd-1]

                let lPrev = lPoint
                let rPrev = rPoint

                let currentConfigData = segmentProfile.configData
                while(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                    lHolesToAdd.push(lPrev)
                    // lPrev.x = lPrev.x + currentConfigData.yardLength
                    lPrev = {
                        x: lPrev.x + currentConfigData.yardLength,
                        y: lPrev.y
                    }

                    if(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                        rHolesToAdd.push(rPrev)
                        // rPrev.x = rPrev.x - currentConfigData.yardLength
                        rPrev = {
                            x: rPrev.x - currentConfigData.yardLength,
                            y: rPrev.y
                        }
                    }
                }

                for(let a=0; a<lHolesToAdd.length; a++) {
                    let check = lHolesToAdd[a]
                    if(check.x < lBar.x ||(check.x > innerlBar.x && check.x < innerrBar.x)) {
                        lHolesToAdd.splice(a, 1)
                        a--
                    }
                    else {
                        currentSegment.holes = this.addHolesToSegment(lHolesToAdd[a], currentSegment.holes)
                    }
                }

                for(let a=0; a<rHolesToAdd.length; a++) {
                    let check = rHolesToAdd[a]
                    if(check.x > rBar.x ||(check.x > innerlBar.x && check.x < innerrBar.x)) {
                        rHolesToAdd.splice(a, 1)
                        a--
                    }
                    else {
                        currentSegment.holes = this.addHolesToSegment(rHolesToAdd[a], currentSegment.holes)
                    }
                }
            }
            else {
                let validLocations = currentSegment.validLocations
                let farLeft = validLocations[0]
                let farRight = validLocations[validLocations.length - 1]

                let lHolesToAdd: Point[] = []
                let rHolesToAdd: Point[] = []

                let lPointInd = farLeft.x
                let rPointInd = farRight.y

                // let lPrev = currentSegment.points[lPointInd]
                // let rPrev = currentSegment.points[rPointInd]
                /*
                Changed the above lines, otherwise it creates holes for the whole line 
                without considering the contour.
                */
                let lPrev = currentSegment.points[lPointInd + 1]
                let rPrev = currentSegment.points[rPointInd - 1]

                let currentConfigData = segmentProfile.configData
                while(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                    let segmentHole: SegmentHole = {
                        location: lPrev,
                        score: 0,
                        colorTally: [],
                        colorId: 10,
                        active: false,
                        keep: 2,
                        depth: 0
                    }
                    currentSegment.holes.push(segmentHole)
                    // lPrev.x = lPrev.x + currentConfigData.yardLength
                    lPrev = {
                        x: lPrev.x + currentConfigData.yardLength,
                        y: lPrev.y
                    }

                    if(rPrev.x - lPrev.x >= currentConfigData.yardLength * currentConfigData.centerTolerance) {
                        let segmentHole: SegmentHole = {
                            location: rPrev,
                            score: 0,
                            colorTally: [],
                            colorId: 10,
                            active: false,
                            keep: 2,
                            depth: 0
                        }
                        currentSegment.holes.push(segmentHole)
                        // rPrev.x = rPrev.x - currentConfigData.yardLength
                        rPrev = {
                            x: rPrev.x - currentConfigData.yardLength,
                            y: rPrev.y
                        }
                    }
                }
            }
        }
        segmentProfile.finalImageData = this.imgdataService.greenImage(segmentProfile.finalImageData.pixels, true)
        // console.log(segmentProfile, '***segments-after-holes')
        return segmentProfile
    }

    addHolesToSegment(holeLoc: Point, segmentHoles: SegmentHole[]) : SegmentHole[] {
        let segmentHole = this.getNewSegmentHole(holeLoc)
        segmentHoles.push(segmentHole)
        return segmentHoles
    }

    scoreHoles(segmentProfile: SegmentProfile) : SegmentProfile {
        let image = this.imgdataService.greenImage(segmentProfile.croppedImageData, true)
        let segments = segmentProfile.segments
        segments.forEach(segment => {
            if(Object.keys(segment.midpoint).length) {
                segment.midpoint = this.scoreSegmentHole(image, segmentProfile.configData.yardLength, segmentProfile.colorProfile, segment.midpoint)
                segment.midpoint.active = false

                if(segment.midpoint.keep == 1) {
                    segment.midpoint.active = true
                }
                else if(segment.midpoint.keep == 2) {
                    segment.midpoint.active = false
                }
            }
            segment.holes.forEach(segmentHole => {
                segmentHole = this.scoreSegmentHole(image, segmentProfile.configData.yardLength, segmentProfile.colorProfile, segmentHole)

                if(segmentHole.keep ==  1) {
                    segmentHole.active = true
                }
                else if(segmentHole.keep == 2) {
                    segmentHole.active = false
                }
            });
        });

        segmentProfile.addedHoles.forEach(segmentHole => {
            segmentHole = this.scoreSegmentHole(image, segmentProfile.configData.yardLength, segmentProfile.colorProfile, segmentHole)

            if(segmentHole.keep == 1) {
                segmentHole.active = true
            }
            else if(segmentHole.keep == 2) {
                segmentHole.active = false
            }
        });

        return segmentProfile
    }

    scoreSegmentHole(image: GreenImageData, yardLength: number, colorProfile: any, segmentHole: SegmentHole) : SegmentHole {
        let score = 0
        let loc = segmentHole.location
        segmentHole.colorTally = []
        for(let a=0; a<10; a++) {
            segmentHole.colorTally.push(0)
        }

        for(let y = loc.y - yardLength/2; y < loc.y + yardLength/2; y++) {
            for(let x = loc.x - yardLength/2; x < loc.x + yardLength/2; x++) {
                if(x > 0 && x < image.width && y > 0 && y < image.height) {
                    let c = this.imgdataService.getColor(image, Math.round(x), Math.round(y))
                    for(let a=0; a<colorProfile.length; a++) {
                        let d = colorProfile[a]
                        if(c.R > d.r-30 && c.R < d.r+30 && c.G < d.g+30 && c.G > d.g-30 && c.B < d.b+30 && c.B > d.b-30) {
                            segmentHole.colorTally[a]++
                        }
                    }
                }
            }
        }

        for(let a=0; a < segmentHole.colorTally.length; a++) {
            segmentHole.score += (100 - (a * 10)) * segmentHole.colorTally[a]
        }

        let largest = 0
        let index = 0
        for(let a=0; a<segmentHole.colorTally.length; a++) {
            if(segmentHole.colorTally[a] >= largest) {
                largest = segmentHole.colorTally[a]
                index = a
            }
        }

        segmentHole.colorId = index

        // if(this.colorProfile[segmentHole.colorId].enabled) {
        if(colorProfile[segmentHole.colorId].enabled) {
            segmentHole.active = true
        }
        else {
            segmentHole.active = false
        }

        return segmentHole
    }

    async blurGreen(segmentProfile: SegmentProfile, useHiddenCanvas: boolean = false, isResize: boolean = true) : Promise<SegmentProfile> {
        return new Promise((resolve, reject) => {
            let hiddenCanvas = <HTMLCanvasElement>this.canvasContainer.getElementsByClassName("hidden-canvas")[0]
            let hiddenCtx = <CanvasRenderingContext2D>hiddenCanvas.getContext('2d')
            hiddenCtx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height)
            hiddenCanvas.width = segmentProfile.croppedImageData.width
            hiddenCanvas.height = segmentProfile.croppedImageData.height
            hiddenCtx.putImageData(segmentProfile.croppedImageData, 0, 0)

            let canvas = this.canvas
            if(useHiddenCanvas) {
                canvas = hiddenCanvas
            }

            // this.ctx = <CanvasRenderingContext2D>this.canvas.getContext('2d')
            // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            // this.canvas.width = segmentProfile.finalImageData.width
            // this.canvas.height = segmentProfile.finalImageData.height
            // this.ctx.putImageData(segmentProfile.finalImageData.pixels, 0, 0)
            // this.ctx.filter = 'blur(' + segmentProfile.configData.blurAmount +'px)'
            hiddenCtx.filter = 'blur(' + segmentProfile.configData.blurAmount +'px)'
            // this.image.src = this.canvas.toDataURL()
            this.image.src = hiddenCanvas.toDataURL()
            this.image.onload = async () => {
                // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                // this.ctx.drawImage(this.image, 0, 0)
                // let imgData = this.ctx.getImageData(0, 0, this.image.width, this.image.height)
                hiddenCtx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
                hiddenCtx.drawImage(this.image, 0, 0)
                let imgData = hiddenCtx.getImageData(0, 0, this.image.width, this.image.height)

                let greenImage = this.imgdataService.greenImage(imgData)

                let originalData = this.imgdataService.greenImage(segmentProfile.croppedImageData)
                for(let y=0; y<originalData.height; y++) {
                    for(let x=0; x<originalData.width; x++) {
                        let c: Color = this.imgdataService.getColor(originalData, x, y)
                        if(c.A > 0 && c.R < 40 && c.G < 40 && c.B < 40) {
                            greenImage = this.setColor(greenImage, x , y, 0, 0, 0)
                        }
                    }
                }

                canvas.width = hiddenCanvas.width
                canvas.height = hiddenCanvas.height
                let ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.putImageData(greenImage.pixels, 0, 0)
                let widthRatio: number = 1
                let heightRatio: number = 1
                if(isResize)
                {
                    [widthRatio, heightRatio] = await this.resizeImage(this.canvasContainer, canvas)
                }

                segmentProfile.shrinkedWidthRatio = widthRatio
                segmentProfile.shrinkedHeightRatio = heightRatio
                let newImageData = this.imgdataService.greenImage(greenImage.pixels, true)
                segmentProfile.finalImageData = this.imgdataService.greenImage(this.cropImage(newImageData, useHiddenCanvas), true)
                console.log(segmentProfile, '***after-blur')
                resolve(segmentProfile)
            }
        })

        // this.ctx = <CanvasRenderingContext2D>this.canvas.getContext('2d')
        // this.ctx.filter = 'blur(' + segmentProfile.configData.blurAmount +'px)'
        // this.ctx.drawImage(this.image, 0, 0)
        // let imgData = this.ctx.getImageData(0, 0, this.image.width, this.image.height)
        // let greenImage = this.imgdataService.greenImage(imgData)

        // let originalData = this.imgdataService.greenImage(segmentProfile.croppedImageData)
        // for(let y=0; y<originalData.height; y++) {
        //     for(let x=0; x<originalData.width; x++) {
        //         let c: Color = this.imgdataService.getColor(originalData, x, y)
        //         if(c.A > 0 && c.R < 40 && c.G < 40 && c.B < 40) {
        //             greenImage = this.SetColor(greenImage, x , y, 0, 0, 0)
        //         }
        //     }
        // }

        // this.ctx.putImageData(greenImage.pixels, 0, 0)
        // let newImageData = this.imgdataService.greenImage(greenImage.pixels, true)
        // segmentProfile.finalImageData = this.imgdataService.greenImage(this.CropImage(newImageData), true)
        // console.log(segmentProfile, '***after-blur')
        // return segmentProfile

    }

    setColor(imageData: GreenImageData, x: number, y: number, R: number, G: number, B: number) : GreenImageData {
        imageData.pixels.data[(y * (imageData.width * 4) + x * 4)] = R
        imageData.pixels.data[(y * (imageData.width * 4) + x * 4) + 1] = G
        imageData.pixels.data[(y * (imageData.width * 4) + x * 4) + 2] = B

        return imageData
    }

    drawHoles(segmentProfile: SegmentProfile, widthRatio: number, heightRatio: number, moveHoles: boolean = false) {
        let holeId = 1
        segmentProfile.segments.forEach((segment) => {
            segment.holes.forEach(hole => {
                const el = document.createElement('div')
                el.setAttribute('id', `hole-${holeId}`)
                el.setAttribute('style',
                                `top: ${(hole.location.y - (segmentProfile.configData.yardLength/4))*heightRatio}px;
                                left: ${(hole.location.x - (segmentProfile.configData.yardLength/4))*widthRatio}px;
                                width: ${(segmentProfile.configData.yardLength/2)*widthRatio}px;
                                height: ${(segmentProfile.configData.yardLength/2)*heightRatio}px`
                )
                if(hole.active) {
                    el.classList.add('hole-fill')
                }
                else {
                    el.classList.add('hole-empty')
                }
                this.canvasContainer.appendChild(el)
                holeId++
            })

            if(Object.keys(segment.midpoint).length) {
                const el = document.createElement('div')
                el.setAttribute('id', `hole-${holeId}`)
                el.setAttribute('style',
                                `top: ${(segment.midpoint.location.y - (segmentProfile.configData.yardLength/4))*heightRatio}px;
                                left: ${(segment.midpoint.location.x - (segmentProfile.configData.yardLength/4))*widthRatio}px;
                                width: ${(segmentProfile.configData.yardLength/2)*widthRatio}px;
                                height: ${(segmentProfile.configData.yardLength/2)*heightRatio}px`
                )
                if(segment.midpoint.active) {
                    el.classList.add('hole-fill')
                }
                else {
                    el.classList.add('hole-empty')
                }
                this.canvasContainer.appendChild(el)
                holeId++
            }
        })

        segmentProfile.addedHoles.forEach((hole) => {
            const el = document.createElement('div')
            el.setAttribute('id', `hole-${holeId}`)
            el.setAttribute('style',
                            `top: ${(hole.location.y - (segmentProfile.configData.yardLength/4))*heightRatio}px;
                            left: ${(hole.location.x - (segmentProfile.configData.yardLength/4))*widthRatio}px;
                            width: ${(segmentProfile.configData.yardLength/2)*widthRatio}px;
                            height: ${(segmentProfile.configData.yardLength/2)*heightRatio}px`
            )
            if(hole.active) {
                el.classList.add('hole-fill')
            }
            else {
                el.classList.add('hole-empty')
            }
            this.canvasContainer.appendChild(el)
            holeId++
        })

        if(moveHoles) {
            this.moveHoles(100, this.canvasContainer, widthRatio, heightRatio)
        }
    }

    drawHolesForPdf(canvasContainer: HTMLElement, segmentProfile: SegmentProfile, includeInactiveHoles: boolean, widthRatio: number, heightRatio: number) {
        let holeId = 1
        segmentProfile.segments.forEach((segment) => {
            segment.holes.forEach(hole => {
                const el = document.createElement('div')
                el.setAttribute('id', `hole-${holeId}`)
                el.setAttribute('style',
                                `top: ${(hole.location.y - (segmentProfile.configData.yardLength/4)) * heightRatio}px;
                                left: ${(hole.location.x - (segmentProfile.configData.yardLength/4)) * widthRatio}px;
                                width:${(segmentProfile.configData.yardLength/2) * widthRatio}px; 
                                height:${(segmentProfile.configData.yardLength/2) * heightRatio}px`
                )
                if(hole.active) {
                    el.classList.add('hole-fill')
                }
                else if(includeInactiveHoles) {
                    el.classList.add('hole-empty')
                }
                canvasContainer.appendChild(el)
                holeId++
            })

            if(Object.keys(segment.midpoint).length) {
                const el = document.createElement('div')
                el.setAttribute('id', `hole-${holeId}`)
                el.setAttribute('style',
                                `top: ${(segment.midpoint.location.y - (segmentProfile.configData.yardLength/4)) * heightRatio}px;
                                left: ${(segment.midpoint.location.x - (segmentProfile.configData.yardLength/4)) * widthRatio}px;
                                width:${(segmentProfile.configData.yardLength/2) * widthRatio}px; 
                                height:${(segmentProfile.configData.yardLength/2) * heightRatio}px`
                )
                if(segment.midpoint.active) {
                    el.classList.add('hole-fill')
                }
                else if(includeInactiveHoles) {
                    el.classList.add('hole-empty')
                }
                canvasContainer.appendChild(el)
                holeId++
            }
        })

        segmentProfile.addedHoles.forEach((hole) => {
            const el = document.createElement('div')
            el.setAttribute('id', `hole-${holeId}`)
            el.setAttribute('style',
                            `top: ${(hole.location.y - (segmentProfile.configData.yardLength/4)) * heightRatio}px;
                            left: ${(hole.location.x - (segmentProfile.configData.yardLength/4)) * widthRatio}px;
                            width:${(segmentProfile.configData.yardLength/2) * widthRatio}px; 
                            height:${(segmentProfile.configData.yardLength/2) * heightRatio}px`
            )
            if(hole.active) {
                el.classList.add('hole-fill')
            }
            else if(includeInactiveHoles) {
                el.classList.add('hole-empty')
            }
            canvasContainer.appendChild(el)
            holeId++
        })
        // this.moveHoles(100, canvasContainer)
        this.moveHolesForPDF(100 * widthRatio, 100 * heightRatio, canvasContainer)
    }

    drawGrid(segmentProfile: SegmentProfile, widthRatio: number, heightRatio: number, useHiddenCanvas: boolean = false) {
        let canvas = this.canvas
        if(useHiddenCanvas) {
            let hiddenCanvas = <HTMLCanvasElement>this.canvasContainer.getElementsByClassName("hidden-canvas")[0]
            canvas = hiddenCanvas
        }
        let finalImageData = segmentProfile.finalImageData
        let configData = segmentProfile.configData

        canvas.width = (segmentProfile.finalImageData.width + 200)*widthRatio
        canvas.height = (segmentProfile.finalImageData.height + 200)*heightRatio

        let context = <CanvasRenderingContext2D>canvas.getContext("2d");
        context?.putImageData(finalImageData.pixels, 100*widthRatio, 100*heightRatio)

        if(!useHiddenCanvas)
            this.moveHoles(100, this.canvasContainer, widthRatio, heightRatio)

        let end = Math.ceil((finalImageData.right - finalImageData.left)/(configData.yardLength*widthRatio))
        end *= (configData.yardLength*widthRatio)

        let finishedY = 0
        let count = 0
        for(let y = finalImageData.bottom+100*heightRatio; y > 0 && y > finalImageData.top+100*heightRatio - configData.yardLength*heightRatio; y -= configData.yardLength*heightRatio) {
            //draw a line
            context?.moveTo(finalImageData.left+100*widthRatio, y)
            context?.lineTo(end+100*widthRatio, y)
            context?.stroke()

            //write numbers
            if(count > 0 && count < 10) {
                context.font = `${(configData.yardLength*heightRatio)/2}px Arial`
                context.fillText(count.toString(), finalImageData.left + 65*widthRatio, y + 5)
            }
            else if(count > 0 && count > 9) { //condition can be simplified
                context.font = `${(configData.yardLength*heightRatio)/2}px Arial`
                context.fillText(count.toString(), finalImageData.left + 53*widthRatio, y + 5)
            }

            finishedY = y
            count++
        }

        count = 0
        for(let x = finalImageData.left+100*widthRatio; x <= end+101*widthRatio; x += configData.yardLength*widthRatio) {
            //draw a line
            context?.moveTo(x, finalImageData.bottom+100*heightRatio)
            context?.lineTo(x, finishedY)
            context?.stroke()

            //write numbers
            if(count > 0 && count < 10) {
                context.font = `${(configData.yardLength*widthRatio)/2}px Arial`
                context.fillText(count.toString(), x - 5, finalImageData.bottom + 135*heightRatio)
            }
            else if(count > 0 && count > 9) { //condition can be simplified
                context.font = `${(configData.yardLength*widthRatio)/2}px Arial`
                context.fillText(count.toString(), x - 5, finalImageData.bottom + 135*heightRatio)
            }
            count++
        }
        segmentProfile.finalImageData = this.imgdataService.greenImage(context.getImageData(0, 0, canvas.width, canvas.height))
    }

    drawGridForPdf(segmentProfile: SegmentProfile, widthRatio: number, heightRatio: number, canvas: HTMLCanvasElement) {
        let finalImageData = segmentProfile.finalImageData, configData = segmentProfile.configData

        canvas.width = segmentProfile.finalImageData.width
        canvas.height = segmentProfile.finalImageData.height

        let context = <CanvasRenderingContext2D>canvas.getContext("2d");
        context?.putImageData(finalImageData.pixels, 100*widthRatio, 100*heightRatio)

        let end = Math.ceil((finalImageData.right - finalImageData.left)/(configData.yardLength*widthRatio))
        end *= (configData.yardLength*widthRatio)

        let finishedY = 0
        let count = 0
        context.lineWidth = widthRatio
        for(let y = finalImageData.bottom+100*heightRatio; y > 0 && y > finalImageData.top+100*heightRatio - configData.yardLength*heightRatio; y -= configData.yardLength*heightRatio) {
            //draw a line
            context.moveTo(finalImageData.left+100*widthRatio, y)
            context.lineTo(end+100*widthRatio, y)
            context.stroke()

            //write numbers
            if(count > 0 && count < 10) {
                context.font = `${(configData.yardLength*heightRatio)/2}px Arial`
                context.fillText(count.toString(), finalImageData.left + 65*widthRatio, y + 5)
            }
            else if(count > 0 && count > 9) { //condition can be simplified
                context.font = `${(configData.yardLength*heightRatio)/2}px Arial`
                context.fillText(count.toString(), finalImageData.left + 53*widthRatio, y + 5)
            }

            finishedY = y
            count++
        }

        count = 0
        for(let x = finalImageData.left+100*widthRatio; x <= end+101*widthRatio; x += configData.yardLength*widthRatio) {
            //draw a line
            context.moveTo(x, finalImageData.bottom+100*heightRatio)
            context.lineTo(x, finishedY)
            context.stroke()

            //write numbers
            if(count > 0 && count < 10) {
                context.font = `${(configData.yardLength*widthRatio)/2}px Arial`
                context.fillText(count.toString(), x - 5, finalImageData.bottom + 135*heightRatio)
            }
            else if(count > 0 && count > 9) { //condition can be simplified
                context.font = `${(configData.yardLength*widthRatio)/2}px Arial`
                context.fillText(count.toString(), x - 5, finalImageData.bottom + 135*heightRatio)
            }
            count++
        }
    }

    moveHoles(offsetPixels: number, canvasContainer: HTMLElement, widthRatio: number, heightRatio: number) {

        let holes = canvasContainer.getElementsByTagName("div")

        for (let i = 0; i < holes.length; i++) {
            holes[i].style.left = `${+(holes[i].style.left).split('p')[0] + offsetPixels*widthRatio}px`
            holes[i].style.top = `${+(holes[i].style.top).split('p')[0] + offsetPixels*heightRatio}px`
        }
    }

    moveHolesForPDF(widthOffset: number, heightOffset: number, canvasContainer: HTMLElement) {

        let holes = canvasContainer.getElementsByTagName("div")

        for (let i = 0; i < holes.length; i++) {
            holes[i].style.left = `${+(holes[i].style.left).split('p')[0] + widthOffset}px`
            holes[i].style.top = `${+(holes[i].style.top).split('p')[0] + heightOffset}px`
        }
    }

    setCounts(segmentProfile: SegmentProfile) : SegmentProfile {
        let holeCountData = []
        let totalActive = 0
        let totalAvailable = 0

        let counts = this.getCurrentSegmentCounts(segmentProfile)

        for(let a=0; a<segmentProfile.colorProfile.length; a++) {
            let hcd : HoleCountData = {
                name: segmentProfile.colorProfile[a].name,
                activeHoles: counts[0][a],
                availableHoles: counts[1][a],
                enabled: counts[2][a]
            }
            holeCountData.push(hcd)
            totalActive += counts[0][a]
            totalAvailable += counts[1][a]
        }
        let totalHoleCountData: HoleCountData = {
            name: 'total',
            activeHoles: totalActive,
            availableHoles: totalAvailable,
            enabled: 0
        }
        holeCountData.push(totalHoleCountData)
        segmentProfile.holeCountData = holeCountData
        return segmentProfile
    }

    getCurrentSegmentCounts(currentSegmentProfile: SegmentProfile) : number[][] {
        let activeCounts: number[] = []
        let totalCounts: number[] = []
        let enables: number[] = []

        for(let a=0; a<currentSegmentProfile.colorProfile.length; a++) {
            activeCounts.push(0)
            totalCounts.push(0)

            if(currentSegmentProfile.colorProfile[a].enabled)
                enables.push(1)
            else
                enables.push(0)
        }

        currentSegmentProfile.segments.forEach(segment => {
            segment.holes.forEach(hole => {
                if(hole.active)
                    activeCounts[hole.colorId]++
                totalCounts[hole.colorId]++
            });

            let mid = segment.midpoint
            if(Object.keys(mid).length) {
                if(mid.active) {
                    activeCounts[mid.colorId]++
                }
                totalCounts[mid.colorId]++
            }
        });

        currentSegmentProfile.addedHoles.forEach(hole => {
            if(hole.active)
                    activeCounts[hole.colorId]++
            totalCounts[hole.colorId]++
        })

        let counts = []
        counts.push(activeCounts, totalCounts, enables)

        return counts
    }

    /* 
    While shrinking image goes blurry - need to check
    */
    resizeImage(canvasContainer: HTMLElement, canvas: HTMLCanvasElement) : Promise<number[]> {
        return new Promise((resolve, reject) => {
            // let canvas = <HTMLCanvasElement>canvasContainer.querySelector(".image-canvas")
            let imageElement = <HTMLImageElement>canvasContainer.querySelector(".green-image")
            this.image = imageElement
            // this.canvas = canvas
            this.image.src = canvas.toDataURL();
    
            let imageWidth = canvas.width
            let imageHeight = canvas.height
    
            let finalWidth = imageWidth
            let finalHeight = imageHeight
    
            if(imageHeight > this.resizeHeight) {
                let ratio = this.resizeHeight/imageHeight
                let tempWidth = ratio * imageWidth
                let tempHeight = this.resizeHeight
                let ratio2 =1
                if(tempWidth > this.resizeWidth) {
                    ratio2 = this.resizeWidth/tempWidth
                    tempWidth = this.resizeWidth
                }
                finalHeight = ratio2 * tempHeight
                finalWidth = tempWidth
            }
            else if(imageWidth > this.resizeWidth) {
                let ratio = this.resizeWidth/imageWidth
                finalHeight = ratio * imageHeight
                finalWidth = this.resizeWidth
            }
            let ctx = <CanvasRenderingContext2D>canvas.getContext('2d')
    
            canvas.width = finalWidth
            canvas.height = finalHeight
    
            this.image.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(this.image, 0, 0, finalWidth, finalHeight)
    
                let widthRatio = finalWidth/imageWidth
                let heightRatio = finalHeight/imageHeight
    
                resolve([widthRatio, heightRatio])
            }
        })
        // this.changeHoleSizeAndPosition(canvasContainer, heightRatio, widthRatio)
    }

    changeHoleSizeAndPosition(canvasContainer: HTMLElement, heightRatio: number, widthRatio: number) {
        let holes = canvasContainer.querySelectorAll("div")
        holes.forEach(hole => {
            hole.style.left = `${+(hole.style.left).split('p')[0] * widthRatio}px`
            hole.style.top = `${+(hole.style.top).split('p')[0] * heightRatio}px`
            hole.style.width = `${+(hole.style.width).split('p')[0] * widthRatio}px`
            hole.style.height = `${+(hole.style.height).split('p')[0] * heightRatio}px`
        });
    }

    async onHoleClick(canvasContainer: HTMLElement, segmentProfile: SegmentProfile, holePoint  : Point) {
        this.canvas = <HTMLCanvasElement>canvasContainer.getElementsByClassName("image-canvas")[0]
        this.ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d")

        this.negateHole(segmentProfile, holePoint)
        /*
        Change: Commented the updateImage function in new code.
        Reason: To create holes we are using css, in old code the holes are drawn as the image.
        */
        // segmentProfile = await this.updateImage(segmentProfile)
        segmentProfile = this.setCounts(segmentProfile)
        return segmentProfile
    }

    negateHole(segmentProfile: SegmentProfile, holePoint  : Point) {
        let yardLength = segmentProfile.configData.yardLength
        let widthRatio = segmentProfile.shrinkedWidthRatio
        let heightRatio = segmentProfile.shrinkedHeightRatio
        holePoint.x -= 100*widthRatio
        holePoint.y -= 100*heightRatio
        segmentProfile.segments.forEach(segment => {
            segment.holes.forEach(hole => {
                this.changeActiveOfHole(hole, holePoint, yardLength, widthRatio, heightRatio)
            });

            let midPoint = segment.midpoint
            if(Object.keys(midPoint).length) {
                this.changeActiveOfHole(midPoint, holePoint, yardLength, widthRatio, heightRatio)
            }
        });

        segmentProfile.addedHoles.forEach(hole => {
            this.changeActiveOfHole(hole, holePoint, yardLength, widthRatio, heightRatio)
        });
    }

    changeActiveOfHole(hole : SegmentHole,   point: Point, yardLength: number, widthRatio: number, heightRatio: number) {
        this.ctx.beginPath()
        this.ctx.rect((hole.location.x - yardLength/3.0)*widthRatio, (hole.location.y - yardLength/3.0)*heightRatio, ((yardLength * 2.0)/3.0)*widthRatio, ((yardLength * 2.0)/3.0)*heightRatio)
        if(this.ctx.isPointInPath(point.x, point.y)) {
            hole.active =  !hole.active
            if(hole.active) {
                hole.keep = 1
            }
            else {
                hole.keep = 2
            }
        }
    }

    async updateImage(segmentProfile: SegmentProfile, blurred: boolean = false) : Promise<SegmentProfile> {
        if(!blurred)
            segmentProfile = await this.blurGreen(segmentProfile)
        this.drawHoles(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        this.drawGrid(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        return segmentProfile
    }

    setEnables(colorProfile: any, enables: number[]) : any {
        for(let i=0; i<enables.length; i++) {
            colorProfile[i].enabled = enables[i] == 1
        }
        return colorProfile
    }

    async reexecute(canvasContainer: HTMLElement, segmentProfile: SegmentProfile, useHiddenCanvas: boolean = false, isResize: boolean = true, wihtoutGrid:boolean = false) : Promise<SegmentProfile> {
        this.canvas = <HTMLCanvasElement>canvasContainer.getElementsByClassName("image-canvas")[0]
        this.ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d")
        
        segmentProfile = this.scoreHoles(segmentProfile)
        segmentProfile = await this.blurGreen(segmentProfile, useHiddenCanvas, isResize)
        // this.clearHoles(canvasContainer)
        if(!useHiddenCanvas)
            this.drawHoles(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        if(!wihtoutGrid)
            this.drawGrid(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio, useHiddenCanvas)
        
        segmentProfile = this.setCounts(segmentProfile)
        return segmentProfile
    }

    async reexecuteForPdf(canvasContainer: HTMLElement, segmentProfile: SegmentProfile, includeInactiveHoles: boolean) : Promise<SegmentProfile> {
        this.canvas = <HTMLCanvasElement>canvasContainer.getElementsByClassName("image-canvas")[0]
        this.ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d")
        segmentProfile = this.scoreHoles(segmentProfile)
        segmentProfile = await this.blurGreen(segmentProfile)
        this.clearHoles(canvasContainer)
        // this.drawHolesForPdf(canvasContainer, segmentProfile, includeInactiveHoles, )
        this.drawGrid(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        // segmentProfile = this.setCounts(segmentProfile)
        return segmentProfile
    }

    async addNewHole(segmentProfile: SegmentProfile, x: number, y: number) {
        let [point, isBlurred] = await this.snapHole(x, y, segmentProfile)
        let newSegmentHole = this.getNewSegmentHole(point)
        newSegmentHole.active = true
        newSegmentHole.keep = 1
        newSegmentHole.depth = y
        segmentProfile.addedHoles.push(newSegmentHole)
        segmentProfile.addedHoleNames = this.addPoint(segmentProfile.addedHoleNames, x, y)

        segmentProfile = this.scoreHoles(segmentProfile)
        segmentProfile = await this.updateImage(segmentProfile, isBlurred)
        segmentProfile = this.setCounts(segmentProfile)
        return segmentProfile
    }

    getNewSegmentHole(location: Point) {
        let segmentHole: SegmentHole = {
            location: location,
            score: 0,
            colorTally: [],
            colorId: 10,
            active: false,
            keep: 0,
            depth: 0
        }
        return segmentHole
    }

    checkNewHoleLocation(segmentProfile: SegmentProfile, x: number, y: number) : boolean {
        let yardLength = segmentProfile.configData.yardLength
        let finalX = x * yardLength
        let finalY = segmentProfile.croppedImageData.height - y * yardLength - 1
        if(finalY < 0 || finalY > segmentProfile.croppedImageData.height) {
            return false
        }
        let borderPoints: Array<Point[]> = []
        let onTrans = true
        let points: Point[] = []
        let offset = 0.5 * yardLength
        let roundFinalY = Math.round(finalY)
        for(let x=0; x <= segmentProfile.croppedImageData.width; x++) {
            let color = this.imgdataService.getColor(this.imgdataService.greenImage(segmentProfile.croppedImageData, true), x, roundFinalY)
            if(color.A != 0 && onTrans) {
                points.push({x: x, y: finalY})
                onTrans = false
            }
            else if(!onTrans && (color.A == 0 || x == segmentProfile.croppedImageData.width - 1)) {
                points.push({x: x, y: finalY})
                borderPoints.push(points)
                onTrans = true
                points = []
            }
        }
        let valid: boolean = true
        for(let i = 0; i < borderPoints.length; i++) {
            let points = borderPoints[i]
            if(finalX > points[1].x || finalX < points[0].x) {
                if(Math.abs(finalX - points[1].x) < offset || Math.abs(finalX - points[0].x) < offset) {
                    valid = true
                }
                else {
                    valid = false
                }
            }
            else {
                valid = true
            }
        }
        return valid
    }

    async snapHole(x: number, y: number, segmentProfile:SegmentProfile): Promise<any> {
        let final_x = x
        let final_y = y
        let isBlurred = false

        let indices = []
        let segments = segmentProfile.segments
        let yardLength = segmentProfile.configData.yardLength
        // for(let i=0; i<segments.length; i++) {
        //     if(Math.abs(segments[i].depth - y) < 0.5) {
        //         indices.push(i)
        //     }
        // }
        
        // if(indices.length > 0) {
        //     for(let i = 0; i < indices.length; i++) {
        //         for(let j = 0; j < segments[indices[i]].holes.length; j++) {
        //             let segmentHole = segments[indices[i]].holes[j]
        //             console.log(x, y, '***points')
        //             /*
        //             Need to work on the logic if below condition fails.
        //             Currently it is placing the holes in the x, y position which are given.
        //             */
        //             if(Math.abs(segmentHole.location.x - (x * yardLength)) < 1.5 * yardLength) {
        //                 // if(x < segmentHole.location.x) { 
        //                 /*
        //                 Above condition is wrong. 
        //                 If we use this it will never fails.
        //                 It will affect the flow if we try to add holes to right side image.
        //                 */
        //                 if((x * yardLength) < segmentHole.location.x) {
        //                     final_x = segmentHole.location.x - yardLength
        //                     final_y = segmentHole.location.y
        //             // }
        //         }
        //     }
        // }
        // else {
            segmentProfile = await this.blurGreen(segmentProfile, true)
            isBlurred = true

            final_x = x * yardLength
            // final_y = segmentProfile.finalImageData.height - y * yardLength - 200
            /*
            For the new code changed the final_y position like below.
            */
            final_y = segmentProfile.finalImageData.height - y * yardLength - 1

            let guideHoles: Point[] = []
            let l_x = 0
            let r_x = 0

            let blurred = this.imgdataService.greenImage(segmentProfile.croppedImageData, true)
            let roundFinalY = Math.round(final_y)
            for(let i = 0; i < blurred.width; i++) {
                let c = this.imgdataService.getColor(blurred, i, roundFinalY)
                if(c.A != 0) {
                    r_x = i
                }
            }
            for(let i= blurred.width-1; i >= 0; i--) {
                let c = this.imgdataService.getColor(blurred, i, roundFinalY)
                if(c.A != 0) {
                    l_x = i
                }
            }

            while(r_x - l_x >= 0) {
                guideHoles = this.addPoint(guideHoles, l_x, final_y)
                l_x += yardLength

                guideHoles = this.addPoint(guideHoles, r_x, final_y)
                r_x -= yardLength
            }

            let shortest = 10000
            let shortest_index = 0
            for(let i = 0; i < guideHoles.length; i++) {
                if(Math.abs(final_x - guideHoles[i].x) < shortest) {
                    shortest = Math.abs(final_x - guideHoles[i].x)
                    shortest_index = i
                }
                //In old code they are drawing holes here
            }

            if(guideHoles.length > 0)
                final_x = guideHoles[shortest_index].x
        // }

        let finalPoint: Point = {
            x: final_x,
            y: final_y
        }
        console.log(finalPoint, '***final-snap-point')
        return [finalPoint, isBlurred]
    }

    async removeHoles(holeNames: Point[], segmentProfile: SegmentProfile) {
        let addedHoleNames = segmentProfile.addedHoleNames
        let addedHoles = segmentProfile.addedHoles
        holeNames.forEach(name => {
            for(let i = 0; i < addedHoleNames.length; i++) {
                if(addedHoleNames[i] == name) {
                    addedHoleNames.splice(i, 1)
                    addedHoles.splice(i, 1)
                    break
                }
            }
        });
        segmentProfile = this.scoreHoles(segmentProfile)
        segmentProfile = await this.updateImage(segmentProfile)
        segmentProfile = this.setCounts(segmentProfile)
        return segmentProfile
    }

    async onGreenSwitching(segmentProfile: SegmentProfile) {
        if(!segmentProfile.executed) {
            segmentProfile = await this.executeSegment(segmentProfile)
        }
        else if(segmentProfile.loadedFromSave) {
            segmentProfile = await this.reexecute(this.canvasContainer, segmentProfile)
            segmentProfile.loadedFromSave = false
        }
        /*
        Two if conditions were changed as if elseif and
        else condition is added for again loading the first green
        */
        else {
            // segmentProfile = await this.updateImage(segmentProfile)

            this.canvas.width = segmentProfile.finalImageData.width
            this.canvas.height = segmentProfile.finalImageData.height
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.ctx.putImageData(segmentProfile.finalImageData.pixels, 0, 0)
            this.drawHoles(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio, true)
        }

        segmentProfile = this.setCounts(segmentProfile)

        return segmentProfile
    }

    async loadSegmentBook(jsonFile: File, canvasContainer: HTMLElement, ngxLoader: NgxUiLoaderService, dataset: any, data: any, loadFromFile: boolean = false) : Promise<any[]> {
        this.canvasContainer = canvasContainer
        this.canvas = <HTMLCanvasElement>canvasContainer.querySelector(".image-canvas")
        // this.canvas.style.removeProperty("max-width")
        // this.canvas.style.removeProperty("max-height")
        this.ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d")
        let currentGreen = 0
        let savedData : SaveSegmentModel = {
            segmentProfiles: [],
            legendImageStrings: [],
            configData: undefined,
            colorProfile: undefined,
            clubName: "",
            courseName: "",
            type: "",
            versionNumber: "",
            source: "",
            lastModifiedDate: "",
            legendData: null,
            clientID: "",
            courseID: ""
        }
        let linkClientCourse = false
        if(loadFromFile) {
            [savedData, linkClientCourse] = await this.readJsonFile(jsonFile, savedData, ngxLoader, dataset)
        }
        else {
            savedData = this.readDataFromSegmentData(savedData, data)
        }
        console.log(savedData, '***loaded-segment-data')
        this.segmentProfiles = await this.getSegmentProfilesFromSaveSegmentProfiles(savedData.segmentProfiles, canvasContainer)
        this.colorProfile = this.segmentProfiles[currentGreen].colorProfile
        this.segmentProfiles[currentGreen] = await this.executeFromLoad(this.segmentProfiles[currentGreen])
        console.log(this.segmentProfiles)
        return [this.segmentProfiles, savedData, linkClientCourse]
    }

    readDataFromSegmentData(savedData: SaveSegmentModel, segmentData: any) {
        savedData.segmentProfiles = JSON.parse(segmentData.SegmentProfiles)
        savedData.legendImageStrings = JSON.parse(segmentData.LegendImageStrings)
        savedData.configData = JSON.parse(segmentData.Config)
        savedData.colorProfile = JSON.parse(segmentData.ColorProfile)
        savedData.clubName = segmentData.ClientName
        savedData.courseName = segmentData.CourseName
        savedData.legendData = JSON.parse(segmentData.LegendData)
        savedData.clientID = segmentData.ClientID
        savedData.courseID = segmentData.CourseID

        return savedData
    }

    readJsonFile(jsonFile: File, savedData: SaveSegmentModel, ngxLoader: NgxUiLoaderService, dataset: any): Promise<[SaveSegmentModel, boolean]> {
        return new Promise((resolve, reject) => {
            let ignoreKeys = ['type', 'lastModifiedDate', 'source']
            let newKeys = ['clientID', 'courseID']
            let jsonReader = new FileReader()
            let linkClientCourse = false
            jsonReader.readAsText(jsonFile)
            jsonReader.onload = () => {
                let jsonData = JSON.parse(jsonReader.result as string)
                jsonData = this.checkColorProfile(jsonData, dataset)
                let jsonKeys = Object.keys(jsonData)
                let savedDataKeys = Object.keys(savedData)
                
                if(savedDataKeys.length != jsonKeys.length && !(jsonKeys.includes(newKeys[0]) && jsonKeys.includes(newKeys[1]))) {
                    linkClientCourse = true
                    savedDataKeys = savedDataKeys.filter((v: any) => {
                        if(!newKeys.includes(v)) {
                            return v
                        }
                    })
                }

                if(!jsonKeys.includes('legendData'))
                    savedDataKeys = savedDataKeys.filter(item => item != 'legendData')

                if(jsonKeys.includes('type') && jsonData.type == 'SegmentBook') {
                    console.log(jsonKeys.sort(), '***json-data', savedDataKeys.sort(), '***saved-data')

                    if(JSON.stringify(jsonKeys.sort()) != JSON.stringify(savedDataKeys.sort())) {
                        alert("Invalid json file selected.")
                        ngxLoader.stop()
                    }
                    else {
                        savedData = jsonData
                        console.log(savedData, '***jsoncontent')
                        resolve([savedData, linkClientCourse])
                    }
                }
                else {
                    let loadedKeys = jsonKeys
                    let savedKeys = savedDataKeys.filter((v: any) => {
                        if(!ignoreKeys.includes(v)) {
                            return v
                        }
                    })
                    if(JSON.stringify(loadedKeys.sort()) != JSON.stringify(savedKeys.sort())) {
                        alert("Invalid json file selected.")
                        ngxLoader.stop()
                    }
                    else {
                        savedData = jsonData
                        console.log(savedData, '***saved-data')
                        resolve([savedData, linkClientCourse])
                    }
                }
            }
        })
    }

    checkVersion(a:string, b:string) {
        const x = a.split('.').map(e => parseInt(e, 10));
        const y = b.split('.').map(e => parseInt(e, 10));

        for (const i in x) {
            y[i] = y[i] || 0;
            if (x[i] === y[i]) {
                continue;
            } else if (x[i] > y[i]) {
                return 1;
            } else {
                return -1;
            }
        }
        return y.length > x.length ? -1 : 0;
    }

    /* Function to add the common color profile to the read json data.*/
    checkColorProfile(jsonData: any, dataset: any) {
        let jsonKeys = Object.keys(jsonData)
        if(this.checkVersion(jsonData.versionNumber, '1.0.0') != -1 || jsonKeys.includes('colorProfile')) { /*return to the json read function if common color profile is there.*/
            return jsonData
        }
        else if(jsonData.segmentProfiles && jsonData.segmentProfiles.length > 0) { /*If there is segment profile and it is not empty.*/
            let jsonColorProfile = jsonData.segmentProfiles[0].colorProfile
            if(jsonColorProfile && jsonColorProfile.length > 0) {
                jsonColorProfile = jsonColorProfile.map((v: any) => {
                    return {r: v.r, g: v.g, b: v.b}
                })
                let jsonColorProfileString = JSON.stringify(jsonColorProfile)
                if(jsonColorProfile && jsonColorProfile.length > 0) {
                    for(let i = 1; i < dataset.length; i ++) {
                        let colorProfile = dataset[i].ColorProfile.map((v: any) => {
                            return {r: v.r, g: v.g, b: v.b}
                        })
                        let colorProfileString = JSON.stringify(colorProfile)
                        if(colorProfileString == jsonColorProfileString) { /*If both color profiles match.*/
                            console.log('Color profile matched.')
                            jsonData.colorProfile = dataset[i].ColorProfile
                            return jsonData
                        }
                    }
                    console.warn("Color profile didn't match")
                    return jsonData /*No matches with color profile.*/
                }
                return jsonData
            }
            return jsonData
        }
        return jsonData
    }

    async getSegmentProfilesFromSaveSegmentProfiles(savedSegmentProfiles: SaveSegmentProfile[], canvasContainer: HTMLElement) : Promise<SegmentProfile[]> {
        let segmentProfiles: SegmentProfile[] = []
        
        for(let i=0; i<savedSegmentProfiles.length; i++) {
            let savedSegmentProfile = savedSegmentProfiles[i]
            let segmentProfile: SegmentProfile = {
                colorProfile: savedSegmentProfile.colorProfile,
                configData: savedSegmentProfile.configData,
                originalFileName: savedSegmentProfile.originalFileName,
                croppedImageData: await this.convertStringToImageData(savedSegmentProfile.croppedImageString, canvasContainer),
                executed: savedSegmentProfile.executed,
                loadedFromSave: true,
                finalImageData: {} as GreenImageData,
                segments: savedSegmentProfile.segments,
                addedHoles: savedSegmentProfile.addedHoles,
                addedHoleNames: savedSegmentProfile.addedHoleNames,
                holeCountData: savedSegmentProfile.holeCountData,
                shrinkedWidthRatio: savedSegmentProfile.shrinkedWidthRatio,
                shrinkedHeightRatio: savedSegmentProfile.shrinkedHeightRatio,
                croppedHeight: savedSegmentProfile.croppedHeight ?? 0,
                croppedWidth: savedSegmentProfile.croppedWidth ?? 0,
                borderPixel: savedSegmentProfile.borderPixel ?? [0, 0, 0, 0]
            }
            segmentProfiles.push(segmentProfile)
            // let segmentProfile : SegmentProfile = savedSegmentProfiles[i]
        }

        return segmentProfiles
    }

    convertStringToImageData(imgString: String, canvasContainer: HTMLElement) : Promise<ImageData> {
        return new Promise((resolve, reject) => {
            let hiddenCanvas = <HTMLCanvasElement>canvasContainer.querySelector(".hidden-canvas")
            let ctx = <CanvasRenderingContext2D>hiddenCanvas.getContext("2d")
            let image = new Image()
            image.src = "data:image/png;base64," + imgString
            let greenImg = <HTMLImageElement>canvasContainer.querySelector(".green-image")
            this.image = greenImg
            greenImg.src = image.src
            image.onload = () => {
                hiddenCanvas.width = image.width
                hiddenCanvas.height = image.height
                ctx = <CanvasRenderingContext2D>hiddenCanvas.getContext("2d")
                ctx.drawImage(image, 0, 0)
                resolve(ctx.getImageData(0, 0, hiddenCanvas.width, hiddenCanvas.height))
            }
        })
    }

    async executeFromLoad(segmentProfile: SegmentProfile) {
        segmentProfile = await this.blurGreen(segmentProfile, true)
        this.drawHoles(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        this.drawGrid(segmentProfile, segmentProfile.shrinkedWidthRatio, segmentProfile.shrinkedHeightRatio)
        segmentProfile.executed = true
        segmentProfile.loadedFromSave = false //Added in the new code for reducing the time by again executing the first green
        return segmentProfile
    }

    // getSegmentImage(canvasContainer: HTMLElement, segmentProfile: SegmentProfile) : Promise<string> {
    //     return new Promise((resolve, reject) => {
    //         let originalHeight = canvasContainer.style.height
    //         canvasContainer.style.height = 'auto'
    //         canvasContainer.style.width = 'auto'
    //         canvasContainer.style.overflow = 'show'
    //         html2canvas(canvasContainer).then(function(canvas) {
    //             canvasContainer.style.height = originalHeight
    //             resolve(canvas.toDataURL())
    //         })
    //     })
    // }

    // async onCreatePdfBook(segmentProfiles: SegmentProfile[], pdfFonts: string[], canvasContainer: HTMLElement, clubName: string, courseName:string, copyrightYear: string, includeInactiveHoles: boolean, legendImageStrings: any[], logo: string) {
    //     const pdf = new jsPDF('p', "px", [934, 1209])
    //     pdf.addFileToVFS('BookmanOld.TTF', pdfFonts[0])
    //     pdf.addFont('BookmanOld.TTF', 'BookmanOld', 'normal')
    //     pdf.addFileToVFS('Cambria.ttf', pdfFonts[1])
    //     pdf.addFont('Cambria.ttf', 'Cambria', 'normal')

    //     let i = 0 
    //     let segmentProfile = segmentProfiles[0]
    //     if(!segmentProfile.executed) {
    //         segmentProfile = await this.executeSegment(segmentProfile)
    //     }
    //     else {
    //         segmentProfile = await this.reexecute(canvasContainer, segmentProfile)
    //     }

    //     let pageWidth = pdf.internal.pageSize.getWidth()
    //     let pageHeight = pdf.internal.pageSize.getHeight()

    //     pdf.setFont("BookmanOld")
    //     pdf.setFontSize(28)

    //     let textWidth = pdf.getTextWidth(clubName)
    //     pdf.text(clubName, (pageWidth - textWidth)/2, 100)

    //     textWidth = pdf.getTextWidth(courseName)
    //     pdf.text(courseName, (pageWidth - textWidth)/2, 128)

    //     pdf.setFont("Helvetica")
    //     pdf.setFontSize(15)

    //     textWidth = pdf.getTextWidth("Copyright " + copyrightYear)
    //     pdf.text("Copyright " + copyrightYear, 890 - textWidth, pageHeight - 35)

    //     pdf.setFont("Cambria")
    //     pdf.setFontSize(52)

    //     let pageNumber = (i + 1).toString()
    //     textWidth = pdf.getTextWidth(pageNumber)
    //     pdf.text(pageNumber, pageWidth - (85 + textWidth), 90)

    //     segmentProfile = await this.reexecuteForPdf(canvasContainer, segmentProfile, includeInactiveHoles)

    //     let segmentImage = await this.getSegmentImage(canvasContainer, segmentProfile)

    //     let segmentImageProp = pdf.getImageProperties(segmentImage)
    //     let finalWidth = segmentImageProp.width
    //     let finalHeight = segmentImageProp.height

    //     if(segmentImageProp.height > 769) {
    //         let ratio = 769/segmentImageProp.height
    //         let tempWidth = ratio * segmentImageProp.width
    //         let tempHeight = 769
    //         let ratio2 = 1
    //         if(tempWidth > 934) {
    //             ratio2 = 934/tempWidth
    //             tempWidth = 934
    //         }
    //         finalHeight = ratio2 * tempHeight
    //         finalWidth = tempWidth
    //     }
    //     else if(segmentImageProp.width > 934) {
    //         let ratio = 934/segmentImageProp.width
    //         finalHeight = ratio * segmentImageProp.height
    //         finalWidth = 934
    //     }

    //     pdf.addImage(segmentImage, "PNG", (934 - finalWidth)/2, 270, finalWidth, finalHeight)

    //     let greenName = segmentProfile.originalFileName.slice(0, segmentProfile.originalFileName.indexOf('.')-6)
    //     console.log(greenName, '***name')
    //     let legend = legendImageStrings.find(img => {
    //         if (img.name == greenName + '_legend.png')
    //             return img
    //     })

    //     let imgProp : ImageProperties
    //     if(legend != undefined) {
    //         console.log(legend, '***legend-img')
    //         imgProp = pdf.getImageProperties(legend.imgString)
    //         console.log(imgProp, '***img-prop')

    //         let legendHeight = 0
    //         let legendWidth = 0

    //         if(imgProp.height > 190) {
    //             legendHeight = 190
    //             let ratio = 190/imgProp.height
    //             legendWidth = ratio * imgProp.width
    //         }
    //         pdf.addImage(legend.imgString, 'PNG', 125, pageHeight - legendHeight - 40, legendWidth, legendHeight)
    //     }

    //     imgProp = pdf.getImageProperties(logo)
    //     let logoHeight = 0
    //     let logoWidth = 0
    //     if(imgProp.height > 65) {
    //         logoHeight = 65
    //         let ratio = 65/imgProp.height
    //         logoWidth = ratio * imgProp.width
    //     }
    //     pdf.addImage(logo, 'PNG', 779, pageHeight - logoHeight - 40, logoWidth, logoHeight)

        
    //     pdf.save("test.pdf")
    //     console.log("pdf created")
    // }

    exportCSV(segmentProfile: SegmentProfile, greenImageName: string, CSVData: string[], greenName: number, courseName: string) {
        let holeName = 1
        let midpointPassed = false
        let segments = segmentProfile.segments
        let addedHoles = segmentProfile.addedHoles
        let yardLength = segmentProfile.configData.yardLength
        for(let i = 0; i < segments.length; i++) {
            let segmentHoles = segments[i].holes
            let midpoint = segments[i].midpoint
            segmentHoles.forEach(segmentHole => {
                if(!midpointPassed && Object.keys(midpoint).length) {
                    if(midpoint.active) {
                        if(midpoint.location.x < segmentHole.location.x) {
                            let line = ""
                            line = line.concat(
                                greenName.toString(), ",", 
                                holeName.toString(), ",", 
                                (midpoint.location.x / yardLength).toString(), ",",
                                segments[i].depth.toString(), ",", (midpoint.colorId+1).toString(),",",
                                ",",
                                ","
                            )

                            CSVData.push(line)
                            holeName++

                            midpointPassed = true
                        }
                    }
                }

                if(segmentHole.active) {
                    let line = ""
                    line = line.concat(
                        greenName.toString(), ",", 
                        holeName.toString(), ",", 
                        (segmentHole.location.x / yardLength).toString(), ",",
                        segments[i].depth.toString(), ",", (segmentHole.colorId+1).toString(),",",
                        ",",
                        ","
                    )

                    CSVData.push(line)
                    holeName++
                }
            });
            midpointPassed = false
        }

        addedHoles.forEach(hole => {
            if(hole.active) {
                let line = ""
                line = line.concat(
                    greenName.toString(), ",", 
                    holeName.toString(), ",", 
                    (hole.location.x / yardLength).toString(), ",",
                    hole.depth.toString(), ",", (hole.colorId+1).toString(),",",
                    ",",
                    ","
                )

                CSVData.push(line)
                holeName++
            }
        });
        return holeName - 1 //Added the '- 1' because the hole counts are not matching. In the sample files also it is not matching
    }

    clearHoles(canvasContainer: HTMLElement) {
        let childDivs = canvasContainer.querySelectorAll("div")
        for(let i=0; i< childDivs.length; i++) {
            childDivs[i].remove()
        }
    }
}

